<template>
  <div class="card">
    <div class="md:col-6 col-12">
      <h3>Create a custom tool</h3>
      <div class="flex flex-column gap-2 mb-4 relative">
        <label for="name">Tool name*</label>
        <InputText id="name" v-model="formData.name" :class="{'p-invalid': errors.name}" />
        <small
            v-if="errors.name"
            class="p-error">{{ errors.name }}
        </small>
      </div>
      <div class="flex flex-column gap-2 mb-4 relative">
        <label for="price">Price*</label>
        <InputText id="price" v-model="formData.price" />
        <small
            v-if="errors.price"
            class="p-error">{{ errors.price }}
        </small>
      </div>
      <div class="flex flex-column gap-2 mb-4 relative">
        <label for="price">Profit*</label>
        <InputText id="profit" v-model="formData.profit" />
        <small
            v-if="errors.profit"
            class="p-error">{{ errors.profit }}
        </small>
      </div>
      <div class="flex flex-column gap-2 mb-4 relative">
        <label for="process_time">Process time*</label>
        <InputText id="process_time" v-model="formData.process_time" />
        <small
            v-if="errors.process_time"
            class="p-error">{{ errors.process_time }}
        </small>
      </div>
      <div class="flex flex-column gap-2 mb-4 relative">
        <label for="description">Description*</label>
        <Editor id="description" v-model="formData.description" editorStyle="height: 220px" />
        <small
            v-if="errors.description"
            class="p-error">{{ errors.description }}
        </small>
      </div>
      <div class="flex gap-2 mb-4 align-items-center">
        <label for="process_time" style="width: 80px;">Is popular?</label>
        <InputSwitch v-model="formData.is_popular" value="1" />
      </div>
      <div class="flex gap-2 mb-4 align-items-center">
        <label for="process_time" style="width: 80px;">Is visible?</label>
        <InputSwitch v-model="formData.is_visible" value="1" />
      </div>
      <div class="flex gap-2 mb-4 align-items-center">
        <label for="process_time" style="width: 80px;">Publish</label>
        <InputSwitch v-model="formData.is_published" value="1"/>
      </div>
      <div class="mt-8">
        <Button label="Save" icon="pi pi-save" class="p-button-success" @click="createTool" />
      </div>
    </div>
    <div class="page-bg"></div>
  </div>
</template>

<script>
export default {
  name: "New",
  data() {
    return {
      formData: {},
      errors: {}
    }
  },
  methods: {
    isNotFloatValid(price) {
      const priceValue = parseFloat(price);
      if (isNaN(priceValue) || priceValue < 0) {
        return "Please enter a valid price value!";
      }

      const wholeNumberPrice = Math.round(priceValue * 100);

      if (wholeNumberPrice % 1 !== 0) {
        return "Price should have no more than two decimal places!";
      }

      return false;
    },
    createTool() {
      this.errors = {};
      if(!this.formData.name) this.errors.name = "This field is required";
      if(!this.formData.price) this.errors.price = "This field is required";
      if(!this.formData.profit) this.errors.profit = "This field is required";
      if(!this.formData.process_time) this.errors.process_time = "This field is required";
      if(!this.formData.description) this.errors.description = "This field is required";
      if(this.isNotFloatValid(this.formData.price)) this.errors.price = this.isNotFloatValid(this.formData.price);
      if(this.isNotFloatValid(this.formData.profit)) this.errors.profit = this.isNotFloatValid(this.formData.profit);
      if(Object.keys(this.errors).length) return false;

      this.$store.dispatch('tool/createTool', this.formData).then((res) => {
        if(res.data.success) {
          this.$toast.add({severity: 'success', summary: 'Success', detail: 'Tool created', life: 3000});
          this.$router.push({ name: 'prices-and-tools' });
        }
      }).catch(() => {
        this.$toast.add({severity: 'error', summary: 'Error', detail: "Something went wrong", life: 3000});
      });
    }
  }
}
</script>

<style scoped>
.card {
  overflow: hidden;
  position: relative;
}
.page-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 50%;
  margin-bottom: -57px;
  opacity: 0.5;
  width: 800px;
  height: 488px;
  background: url("/images/nt-bg.jpg") no-repeat;
}
.p-error {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: -16px;
}
</style>
